.app-report-heading-1 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-transform: capitalize;
  margin-bottom: 8px;
}

.app-report-heading-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.6;
  text-transform: capitalize;
  margin-bottom: 8px;
}

.app-report-heading-3 {
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 10px;
}

.app-report-subtitle-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 8px;
  text-transform: capitalize;
}
