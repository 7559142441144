// @import './google-fonts';
@import './custom-fonts';
@import './mixins';

// base font styles

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-size: 14px;
  font-family: $font-family !important;
}

input {
  &::shadow {
    div {
      font-family: $font-family !important;
    }
  }
}

h1,
h2,
h3,
h4 {
  margin: 0; // не менять, рушит верстку
}

.h1-sp {
  @include h1-sp-font-styles();
}

.h2-sp {
  @include h2-sp-font-styles();
}

.h3-sp {
  @include h3-sp-font-styles();
}

.h4-sp {
  @include h4-sp-font-styles();
}

.h1-b {
  @include h1-b-font-styles();
}

.h2-b {
  @include h2-b-font-styles();
}

.h2-m {
  @include h2-m-font-styles();
}

.h3-b {
  @include h3-b-font-styles();
}

.h4-b {
  @include h4-b-font-styles();
}

.h4-m {
  @include h4-m-font-styles();
}

.h5-b {
  @include h5-b-font-styles();
}

.h5-m {
  @include h5-m-font-styles();
}

.b1-r {
  @include b1-r-font-styles();
}

.b2-r {
  @include b2-r-font-styles();
}

.b3-r {
  @include b3-r-font-styles();
}

.b4-r {
  @include b4-r-font-styles();
}

.b5-r {
  @include b5-r-font-styles();
}

.c1-r {
  @include c1-r-font-styles();
}

.c1-m {
  @include c1-m-font-styles();
}

.c2-r {
  @include c2-r-font-styles();
}

.c2-m {
  @include c2-m-font-styles();
}

.c3-r {
  @include c3-r-font-styles();
}

.b4-r {
  @include b4-r-font-styles();
}

.app-text-link {
  cursor: pointer;
  text-decoration: underline;

  &--not-underline {
    text-decoration: none;
  }

  &--disabled {
    cursor: default;
    text-decoration: none;

    pointer-events: none;
  }
}

.app-text-truncate {
  @include text-truncate();
}

.app-text-capitalize {
  text-transform: capitalize;
}

.app-text-not-capitalize {
  text-transform: none;
}

.app-text-uppercase {
  text-transform: uppercase;
}

.app-text-white-space-pre {
  white-space: pre;
}

.app-text-white-space-nowrap {
  white-space: nowrap;
}

.app-text-first-letter-up {
  @include first-letter-up();
}
