.app-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 336px;
  padding-top: 48px;
  margin-right: auto;
  position: absolute;
  top: 0;
}

.app-logo-img {
  height: 56px;
  margin-bottom: 50px;
  object-fit: contain;
}

.app-navbar-logo {
  height: 36px;
}
