@import 'theming/functions';

$border-color-05: app-color(bg-color, 0.05);
$border-color-10: app-color(border-color, 0.1);
$border-color-25: app-color(border-color, 0.25);

.app-vertical-divider {
  width: 1px;
  height: 12px;

  background-color: $border-color-25;

  &.--10 {
    background-color: $border-color-10;
  }

  &.--05 {
    background-color: $border-color-05;
  }

  &.--to-right {
    margin-left: auto;

    position: absolute;
    right: 0;
  }

  &.--to-left {
    margin-right: auto;

    position: absolute;
    left: 0;
  }
}

.app-horizontal-divider {
  border: none;
  height: 1px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;

  background-color: $border-color-25;

  &.--no-gap {
    margin: 0;
  }

  &.--10 {
    background-color: $border-color-10;
  }

  &.--05 {
    background-color: $border-color-05;
  }
}

.app-dot {
  width: 4px;
  height: 4px;
  background-color: $border-color-25;
  margin: auto 4px;
  border-radius: 50%;
}

.app-big-dot {
  width: 5px;
  height: 5px;
  background-color: $border-color-10;
  margin: 8px;
  border-radius: 50%;
}
