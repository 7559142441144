.highcharts-legend,
.highcharts-credits {
  display: none;
}

.highcharts-background {
  fill: none;
}

.highcharts-tick {
  display: none;
}

.highcharts-tooltip-container {
  z-index: 1000 !important;
}
