@import 'core/variables';
@import 'theming/functions';

$success-color: app-color(success-color);
$danger-color: app-color(danger-color);
$warning-color: app-color(warning-color);
$text-color-75: app-color(text-color, 0.75);
$text-color-65: app-color(text-color, 0.65);
$border-color-10: app-color(border-color, 0.1);

.mat-mdc-progress-bar.mdc-linear-progress {
  --mdc-linear-progress-track-height: 2px;
  --mdc-linear-progress-active-indicator-height: 2px;
  --mdc-linear-progress-track-color: #{$border-color-10};
  --mdc-linear-progress-active-indicator-color: #{$success-color};

  margin: 4px 0;

  &.--danger {
    --mdc-linear-progress-active-indicator-color: #{$danger-color};
  }

  &.--success {
    --mdc-linear-progress-active-indicator-color: #{$success-color};
  }

  &.--warning {
    --mdc-linear-progress-active-indicator-color: #{$warning-color};
  }

  &.--inactive {
    --mdc-linear-progress-active-indicator-color: #{$text-color-65};
  }

  &.--big {
    --mdc-linear-progress-track-height: 6px;
    --mdc-linear-progress-active-indicator-height: 6px;
    border-radius: 6px;
  }

  .mdc-linear-progress__buffer-dots {
    display: none;
  }
}
