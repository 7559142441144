@import 'screen-sizes';
@import 'core/variables';

.app-page-container {
  width: 100%;
  height: 100%;
  padding: 0px $page-padding-size;

  @include xs_screen() {
    max-width: 767px - 2 * $page-padding-size;
  }

  @include s_screen() {
    max-width: 1024px - 2 * $page-padding-size;
  }

  @include m_screen() {
    max-width: 1025px + 2 * $page-padding-size;
  }

  @include l_screen() {
    max-width: 1242px + 2 * $page-padding-size;
  }
}
