@import 'theming/functions';

$bg-main-color: app-color(bg-main-color);
$text-color: app-color(text-main-color);
$border-color-25: app-color(border-color, 0.25);

.mat-mdc-snack-bar-container {
  color: $text-color;
  background-color: $bg-main-color;
  border: 1px solid $border-color-25;
  border-radius: 4px;

  .mdc-snackbar__surface {
    background-color: transparent !important;
  }
}
