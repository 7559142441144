@import 'theming/functions';
@import 'typography/mixins';

$danger-color: app-color(danger-color);
$danger-color-50: app-color(danger-color, 0.5);
$danger-color-75: app-color(danger-color, 0.75);
$success-color: app-color(success-color);
$success-color-50: app-color(success-color, 0.5);
$success-color-75: app-color(success-color, 0.75);
$warning-color: app-color(warning-color);
$warning-color-50: app-color(warning-color, 0.5);
$warning-color-75: app-color(warning-color, 0.75);
$status-color: app-color(status-color);
$text-link-color: app-color(text-link-color);
$text-color: app-color(text-main-color);
$text-color-addit: app-color(text-color);
$text-color-25: app-color(text-color, 0.25);
$text-color-50: app-color(text-color, 0.5);
$text-color-65: app-color(text-color, 0.65);
$text-color-75: app-color(text-color, 0.75);
$text-inverse-color: app-color(text-inverse-color);
$text-static-color: app-color(text-static-color);
$text-static-light-color: app-color(text-static-light-color);

.app-text-success {
  color: $success-color;

  &-50 {
    color: $success-color-50;
  }

  &-75 {
    color: $success-color-75;
  }
}

.app-text-danger {
  color: $danger-color;

  &-50 {
    color: $danger-color-50;
  }

  &-75 {
    color: $danger-color-75;
  }
}

.app-text-warning {
  color: $warning-color;

  &-50 {
    color: $warning-color-50;
  }

  &-75 {
    color: $warning-color-75;
  }
}

.app-text-link {
  color: $text-link-color;

  &.--dialog-opener {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &:not(.app-text-danger) {
      color: $text-link-color !important;
    }
  }
}

.app-text-color {
  color: $text-color;

  &-addit {
    color: $text-color-addit;
  }

  &-50 {
    color: $text-color-50;
  }

  &-65 {
    color: $text-color-65;
  }

  &-75 {
    color: $text-color-75;
  }

  &-25 {
    color: $text-color-25;
  }

  &-inverse {
    color: $text-inverse-color;
  }

  &-static {
    color: $text-static-color;
  }

  &-static-light {
    color: $text-static-light-color;
  }
}

.app-text-status {
  color: $status-color;
}
