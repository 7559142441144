// Remove the inner padding in Chrome and Safari on macOS.

::-webkit-search-decoration {
  -webkit-appearance: none;
}

// Remove padding around color pickers in webkit browsers

::-webkit-color-swatch-wrapper {
  padding: 0;
}

// Inherit font family and line height for file input buttons

::file-selector-button {
  font: inherit;
}

// 1. Change font properties to `inherit`
// 2. Correct the inability to style clickable types in iOS and Safari.

::-webkit-file-upload-button {
  font: inherit; // 1
  -webkit-appearance: button; // 2
}

// Remove border from iframe

iframe {
  border: 0;
}
