@import 'theming/functions';

$bg-color-20: app-color(bg-color, 0.2);
$bg-color-10: app-color(bg-color, 0.1);
$bg-color-05: app-color(bg-color, 0.05);

.app-cursor-pointer {
  cursor: pointer;
}

.app-hover-cursor-pointer {
  &:hover {
    cursor: pointer !important;
  }
}

.app-hover-text-underline {
  &:hover {
    text-decoration: underline;
  }
}

.app-hover-bg {
  &-20 {
    &:hover {
      background-color: $bg-color-20;
      transition: background-color 0.2s ease-in-out;
    }
  }

  &-10 {
    &:hover {
      background-color: $bg-color-10;
      transition: background-color 0.2s ease-in-out;
    }
  }

  &-05 {
    &:hover {
      background-color: $bg-color-05;
      transition: background-color 0.2s ease-in-out;
    }
  }
}

.app-pointer-events {
  cursor: initial !important;
  pointer-events: all !important;
}
