@import 'typography/mixins';
@import 'theming/functions';

$bg-main-color: app-color(bg-main-color);
$text-color: app-color(text-main-color);
$box-shadow: app-palette-val(box-shadow);

.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    @include c2-r-font-styles(true);

    min-height: 26px;
    border-radius: 6px;
    word-break: break-word;
    white-space: pre-line;
    text-transform: none;
    background-color: $bg-main-color !important;
    color: $text-color !important;
  }

  &.--dynamic {
    .mdc-tooltip__surface {
      min-width: unset !important;
      max-width: unset !important;
    }
  }
}

.app-tooltip-panel {
  border-radius: 4px;
  box-shadow: $box-shadow;
  background-color: $bg-main-color;
}
