@import 'core/variables';
@import 'typography/mixins';
@import 'theming/functions';

$danger-color: app-color(danger-color);
$danger-color-10: app-color(danger-color, 0.1);
$border-color: app-color(border-color);
$border-color-10: app-color(border-color, 0.1);
$border-color-25: app-color(border-color, 0.25);
$border-color-50: app-color(border-color, 0.5);
$text-color: app-color(text-main-color);
$text-color-25: app-color(text-color, 0.25);
$text-color-50: app-color(text-color, 0.5);
$field-focused-color: app-color(field-focused-color);
$bg-main-color: app-color(bg-main-color);
$bg-color: app-color(bg-color);
$bg-color-10: app-color(bg-color, 0.1);
$bg-color-25: app-color(bg-color, 0.25);
$bg-color-65: app-color(bg-color, 0.65);
$text-inverse-color: app-color(text-inverse-color);

ngx-editor {
  position: relative;
  overflow: visible !important;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: $border-color-10 !important;
    opacity: 0.25;
  }

  &[invalid='true'] {
    .NgxEditor {
      border-color: $danger-color !important;
    }
  }
}

.NgxEditor {
  overflow: visible !important;
  background: transparent !important;
  border: 1px solid $border-color-25 !important;
  box-sizing: border-box;
  border-radius: 4px;

  h1 {
    @include h1-b-font-styles();
  }

  h2 {
    @include h2-b-font-styles();
  }

  h3 {
    @include h3-b-font-styles();
  }

  h4 {
    @include h4-b-font-styles();
  }

  p {
    @include c1-r-font-styles();
    text-transform: none !important;
  }

  .NgxEditor__Content {
    min-height: 200px;
    color: $text-color-50 !important;

    // set scrollbar styles
    &::-webkit-scrollbar {
      width: $scrollbar-size;
      height: $scrollbar-size;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $border-color-10 !important;
    }
  }

  .NgxEditor__Placeholder {
    color: $text-color-50 !important;
  }

  label {
    @include c2-r-font-styles();
  }

  label {
    display: flex;
    align-items: center;
  }
}

.NgxEditor__Seperator {
  border-color: $border-color-25 !important;
}

.NgxEditor__HelpText--Error {
  color: $danger-color !important;
}

.NgxEditor__MenuItem--Icon {
  &:hover {
    background-color: $bg-color-25 !important;
  }
}

.NgxEditor__MenuItem--Active {
  background-color: $bg-color-25 !important;
}

.NgxEditor__MenuBar {
  border-radius: 4px;
  border: 1px solid $border-color-25 !important;
  background-color: $bg-main-color !important;
}

.NgxEditor__MenuItem--IconContainer {
  svg {
    fill: $text-color-50 !important;
  }
}

.NgxEditor__Popup--FormGroup input {
  @include c1-r-font-styles();

  height: $input-small-height;
  padding: 0px 0.75em;
  border-width: 1px;
  border-radius: $input-border-radius;
  text-transform: none !important;

  border-color: $border-color-25 !important;
  background-color: $border-color-10 !important;

  color: $text-color !important;
  caret-color: $text-color !important;
}

.NgxEditor__Popup button[type='submit'],
.NgxEditor__MenuItem--Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-top: 18px !important;
  user-select: none;
  height: 20px;
  min-width: 80px;
  border-radius: 2px;

  color: $text-inverse-color !important;
  background: $bg-color !important;

  &:hover {
    background: $bg-color-65 !important;
  }

  @include c1-m-font-styles();
}

.NgxEditor__Popup--Label {
  @include c2-r-font-styles();
  color: $text-color-50 !important;
}

.NgxEditor__Dropdown {
  background: transparent !important;

  &:hover {
    cursor: pointer;
  }

  .NgxEditor__Dropdown--Text {
    @include c1-r-font-styles();
    color: $text-color-50 !important;
  }

  .NgxEditor__Dropdown--Item {
    @include c1-r-font-styles();

    &:hover {
      cursor: pointer;
      color: $text-inverse-color !important;
      background-color: $bg-color !important;
    }

    &.NgxEditor__Dropdown--Active {
      color: $text-inverse-color !important;
    }
  }

  .NgxEditor__Dropdown--Open,
  .NgxEditor__Dropdown--Selected {
    background-color: transparent !important;
  }
}

.NgxEditor__Dropdown--DropdownMenu,
.NgxEditor__Popup {
  color: $text-color !important;
  background: $bg-main-color !important;
  border: 2px solid $border-color-25 !important;
}
