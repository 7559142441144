@import 'core/variables';
@import 'theming/functions';

$text-link-color: app-color(text-link-color);

.app-table-cell {
  display: flex;
  align-items: center;
  height: $table-row-height;
  width: 100%;
}

.app-table-cell-link {
  &:hover {
    font-weight: 500 !important;
    cursor: pointer;
    text-decoration: underline;
  }

  &--active {
    text-decoration: underline;
  }

  &:not(.app-text-danger) {
    color: $text-link-color !important;
  }
}
