@import 'core/variables';
@import 'theming/functions';

$bg-main-color: app-color(bg-main-color);
$bg-color-50: app-color(bg-color, 0.5);
$text-color: app-color(text-main-color);
$border-color-10: app-color(border-color, 0.1);
$border-color-25: app-color(border-color, 0.25);
$box-shadow: app-palette-val(box-shadow);

.cdk-overlay-backdrop {
  background: transparent;
}

.app-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.75);
}

.cdk-overlay-container {
  width: 100vw;
}

.cdk-overlay-pane {
  max-width: 100vw !important;

  .mat-mdc-dialog-container {
    display: flex !important;
    flex-direction: column;

    color: $text-color;
    background-color: $bg-main-color;
    border: 1px solid $border-color-25;
    border-radius: 6px;

    .mdc-dialog__container {
      width: 100%;
    }

    .mat-mdc-dialog-surface.mdc-dialog__surface {
      background-color: transparent;
      box-shadow: $box-shadow;
      border-radius: 6px;

      // set scrollbar styles
      &::-webkit-scrollbar {
        width: $scrollbar-size;
        height: $scrollbar-size;
      }

      // set scrollbar styles
      &::-webkit-scrollbar-thumb {
        background-color: $border-color-10;
        border-radius: 2px;
      }
    }
  }

  &.app-right-side-dialog {
    .mat-mdc-dialog-container {
      border: none !important;
      border-radius: 0 !important;

      .mat-mdc-dialog-surface.mdc-dialog__surface {
        border-radius: 0 !important;
      }
    }
  }

  &:not(.app-right-side-dialog) {
    .mat-mdc-dialog-container {
      overflow: hidden;
    }
  }

  &.app-dialog-popup {
    .mat-mdc-dialog-container {
      border: none !important;
      border-radius: 0 !important;
    }

    &.--no-bg {
      .mat-mdc-dialog-container {
        background-color: transparent;
      }
    }
    &.--border-r4 {
      .mat-mdc-dialog-container {
        border-radius: 4px !important;
      }
    }
  }

  &.app-custom-datepicker-panel {
    border: 1px solid $border-color-25;
    border-radius: 4px;

    color: $text-color;
    background-color: $bg-main-color;
    box-shadow: $box-shadow;
  }
}
