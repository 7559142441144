@import 'typography/mixins';
@import 'core/variables';
@import 'theming/functions';

$bg-color-50: app-color(bg-color, 0.5);
$bg-color-25: app-color(bg-color, 0.25);
$success-color-80: app-color(success-color, 0.8);
$text-color: app-color(text-main-color);
$text-color-25: app-color(text-color, 0.25);
$text-color-75: app-color(text-color, 0.75);
$slide-toggle-color: app-palette-color(dark, text-color);
$slide-toggle-color-25: app-palette-color(dark, text-color, 0.25);
$border-color: app-color(border-color);
$border-color-50: app-color(border-color, 0.5);
$border-color-25: app-color(border-color, 0.25);

.mat-mdc-slide-toggle {
  .mdc-switch__shadow,
  .mdc-switch__ripple {
    display: none;
  }

  .mdc-switch {
    --mdc-switch-track-width: 30px;
    --mdc-switch-track-height: 20px;
    --mdc-switch-track-shape: 16px;
    --mdc-switch-unselected-track-color: #{$bg-color-50};
    --mdc-switch-disabled-unselected-track-color: #{$bg-color-50};
    --mdc-switch-unselected-hover-track-color: #{$bg-color-50};
    --mdc-switch-unselected-focus-track-color: #{$bg-color-50};
    --mdc-switch-unselected-pressed-track-color: #{$bg-color-50};
    --mdc-switch-selected-track-color: #{$success-color-80};
    --mdc-switch-selected-hover-track-color: #{$success-color-80};
    --mdc-switch-selected-focus-track-color: #{$success-color-80};
    --mdc-switch-selected-pressed-track-color: #{$success-color-80};

    --mdc-switch-handle-width: 16px;
    --mdc-switch-handle-height: 16px;
    --mat-switch-selected-handle-size: 16px;
    --mat-switch-unselected-handle-size: 16px;
    --mat-switch-pressed-handle-size: 16px;
    --mdc-switch-handle-surface-color: transparent;
    --mdc-switch-selected-handle-color: #{$slide-toggle-color};
    --mdc-switch-disabled-selected-handle-color: #{$slide-toggle-color};
    --mdc-switch-selected-hover-handle-color: #{$slide-toggle-color};
    --mdc-switch-selected-focus-handle-color: #{$slide-toggle-color};
    --mdc-switch-selected-pressed-handle-color: #{$slide-toggle-color};
    --mdc-switch-unselected-handle-color: #{$slide-toggle-color};
    --mdc-switch-disabled-unselected-handle-color: #{$slide-toggle-color};
    --mdc-switch-unselected-hover-handle-color: #{$slide-toggle-color};
    --mdc-switch-unselected-focus-handle-color: #{$slide-toggle-color};
    --mdc-switch-unselected-pressed-handle-color: #{$slide-toggle-color};

    --mdc-switch-disabled-handle-opacity: 1;
    --mdc-switch-disabled-track-opacity: 1;

    margin-right: 4px;
  }

  .mdc-switch__handle {
    left: 2px;
  }

  .mdc-label {
    @include c2-r-font-styles(true);
    color: $text-color-75;
  }

  .mdc-switch--disabled {
    opacity: 0.4;
  }

  .mdc-switch--disabled + .mdc-label {
    color: $text-color-25 !important;
  }

  &.mat-mdc-slide-toggle-checked {
    .mdc-switch__handle-track {
      transform: translateX(70%);
    }

    .mdc-label {
      color: $text-color;
    }
  }

  &.--small {
    .mdc-switch {
      --mdc-switch-track-width: 27px;
      --mdc-switch-track-height: 16px;

      --mdc-switch-handle-width: 12px;
      --mdc-switch-handle-height: 12px;
      --mat-switch-selected-handle-size: 12px;
      --mat-switch-unselected-handle-size: 12px;
      --mat-switch-pressed-handle-size: 12px;
    }
  }

  &.--outline {
    .mdc-switch {
      --mdc-switch-handle-width: 14px;
      --mdc-switch-handle-height: 14px;
      --mat-switch-selected-handle-size: 14px;
      --mat-switch-unselected-handle-size: 14px;
      --mat-switch-pressed-handle-size: 14px;

      --mdc-switch-selected-handle-color: #{$border-color};
      --mdc-switch-selected-hover-handle-color: #{$border-color};
      --mdc-switch-selected-focus-handle-color: #{$border-color};
      --mdc-switch-selected-pressed-handle-color: #{$border-color};

      --mdc-switch-handle-surface-color: #{$border-color-50};
      --mdc-switch-unselected-handle-color: transparent;
      --mdc-switch-unselected-hover-handle-color: transparent;
      --mdc-switch-unselected-focus-handle-color: transparent;
      --mdc-switch-unselected-pressed-handle-color: transparent;
    }

    .mdc-switch__track {
      border: 1.3px solid $border-color-50;

      &::before,
      &::after {
        background: transparent !important;
      }
    }

    .mdc-switch__handle {
      left: 3px;
    }

    &.mat-mdc-slide-toggle-checked {
      .mdc-switch__handle-track {
        transform: translateX(60%);
      }
    }
  }

  &.--outline.--small {
    .mdc-switch {
      --mdc-switch-handle-width: 10px;
      --mdc-switch-handle-height: 10px;
      --mat-switch-selected-handle-size: 10px;
      --mat-switch-unselected-handle-size: 10px;
      --mat-switch-pressed-handle-size: 10px;
    }
  }
}
