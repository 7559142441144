@import 'theming/functions';

$danger-color: app-color(danger-color);
$danger-color-20: app-color(danger-color, 0.2);
$success-color: app-color(success-color);
$success-color-20: app-color(success-color, 0.2);

.app-blink-animation {
  animation: app-blink-anim 1s linear infinite;
}

@keyframes app-blink-anim {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.app-text-blink-success {
  animation: app-text-blink-success-anim 1.5s 1 ease-in-out;
}

.app-text-blink-danger {
  animation: app-text-blink-danger-anim 1.5s 1 ease-in-out;
}

.app-bg-blink-success {
  animation: app-bg-blink-success-anim 1.5s 1 ease-in-out;
}

.app-bg-blink-danger {
  animation: app-bg-blink-danger-anim 1.5s 1 ease-in-out;
}

@keyframes app-text-blink-success-anim {
  0%,
  100% {
    color: transparent;
  }

  50% {
    color: $success-color;
  }
}

@keyframes app-text-blink-danger-anim {
  0%,
  100% {
    color: transparent;
  }

  50% {
    color: $danger-color;
  }
}

@keyframes app-bg-blink-success-anim {
  0%,
  100% {
    background-color: transparent;
  }

  50% {
    background-color: $success-color-20;
  }
}

@keyframes app-bg-blink-danger-anim {
  0%,
  100% {
    background-color: transparent;
  }

  50% {
    background-color: $danger-color-20;
  }
}
