@import 'theming/functions';

$border-color-10: app-color(border-color, 0.1);
$border-color-25: app-color(border-color, 0.25);

.app-drag-and-drop {
  border-radius: 4px;
  border-width: 1px;
  border-style: dashed;
  border-color: transparent;

  transition: border-color 0.2s ease-in-out;

  &--file-over {
    border-color: $border-color-25;
  }

  &:hover {
    border-color: $border-color-10;
  }

  &--disabled {
    border-color: transparent !important;
  }
}
