@import 'core/variables';
@import 'typography/mixins';
@import 'theming/functions';

$text-color: app-color(text-main-color);
$text-color-25: app-color(text-color, 0.25);
$text-color-65: app-color(text-color, 0.65);
$text-color-75: app-color(text-color, 0.75);

.mat-mdc-radio-button {
  .mat-radio-ripple,
  .mdc-radio__background::before {
    display: none;
  }

  &.mat-accent {
    --mdc-radio-selected-focus-icon-color: #{$text-color-75};
    --mdc-radio-selected-hover-icon-color: #{$text-color-75};
    --mdc-radio-selected-icon-color: #{$text-color-75};
    --mdc-radio-selected-pressed-icon-color: #{$text-color-75};
    --mdc-radio-disabled-selected-icon-color: #{$text-color-65};

    --mdc-radio-unselected-hover-icon-color: #{$text-color-65};
    --mdc-radio-unselected-icon-color: #{$text-color-65};
    --mdc-radio-unselected-pressed-icon-color: #{$text-color-65};
    --mdc-radio-disabled-unselected-icon-color: #{$text-color-65};

    --mat-radio-checked-ripple-color: #{$text-color-75};
    --mat-radio-disabled-label-color: #{$text-color-25};
  }

  .mdc-label {
    @include b3-r-font-styles();

    color: $text-color-65;
  }

  .mdc-radio {
    --mdc-radio-state-layer-size: #{$input-small-height};

    &:not(.mdc-radio--disabled) + .mdc-label {
      &:hover {
        color: $text-color;
      }
    }
  }
}
