@import 'core/variables';
@import 'theming/functions';

$bg-main-color: app-color(bg-main-color);
$text-color: app-color(text-main-color);
$text-color-65: app-color(text-color, 0.65);
$text-color-25: app-color(text-color, 0.25);
$border-color-10: app-color(border-color, 0.1);
$border-color-65: app-color(border-color, 0.65);
$bg-toggle-color: app-color(bg-toggle-color);
$bg-toggle-checked-color: app-color(bg-toggle-checked-color);

.mat-mdc-tab-header {
  --mat-tab-header-active-label-text-color: #{$text-color};
  --mat-tab-header-active-focus-label-text-color: #{$text-color};
  --mat-tab-header-active-hover-label-text-color: #{$text-color};
  --mat-tab-header-inactive-label-text-color: #{$text-color-65};
  --mat-tab-header-inactive-hover-label-text-color: #{$text-color-65};
  --mat-tab-header-inactive-focus-label-text-color: #{$text-color-65};

  flex-shrink: initial !important;
  align-items: center;
  border-radius: $tabs-radius;
  width: 100%;
  height: $nav-height;
  padding: 0px 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  .mat-mdc-tab-header-pagination {
    min-width: auto !important;
    box-shadow: none;

    &.mat-mdc-tab-header-pagination-before {
      width: 14px !important;
      min-width: 14px !important;
      max-width: 14px !important;
      height: 14px !important;
      min-height: 14px !important;
      max-height: 14px !important;
      padding-left: 0px;
    }

    &.mat-mdc-tab-header-pagination-after {
      width: 14px !important;
      min-width: 14px !important;
      max-width: 14px !important;
      height: 14px !important;
      min-height: 14px !important;
      max-height: 14px !important;
      padding-right: 0px;
    }

    .mat-mdc-tab-header-pagination-chevron {
      width: 8px;
      height: 8px;
      border-color: $text-color-65 !important;
    }
  }
}

.mat-mdc-tab-group {
  width: 100%;

  .mdc-tab__ripple,
  .mdc-tab-indicator {
    display: none;
  }

  .mat-mdc-tab-header {
    background-color: $bg-main-color !important;
    border: 1px solid $border-color-10;
  }

  .mat-mdc-tab-body-content {
    height: auto !important;
  }

  .mat-mdc-tab {
    --mdc-secondary-navigation-tab-container-height: 32px;

    padding: 0px;
    margin-right: 20px;
    min-width: auto;
    flex-grow: unset !important;

    &:last-child {
      margin-right: 0px;
    }

    .mdc-tab__text-label {
      height: auto;
      padding: 0px;
      min-width: auto;

      @include c2-r-font-styles();
    }

    &.mdc-tab--active,
    &.mdc-tab--active:focus {
      .mdc-tab__text-label {
        @include c2-m-font-styles();
      }
    }
  }

  &.--as-toggle {
    .mat-mdc-tab-header {
      border: none !important;
      padding: 0px;
      height: 24px;
    }

    .mat-mdc-tab {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }

      .mdc-tab__text-label {
        padding: 0px 24px;
        height: 24px;
        border-radius: 4px;
      }

      &.mdc-tab--active .mdc-tab__text-label {
        background-color: $bg-toggle-checked-color;
      }

      &:not(.mdc-tab--active) .mdc-tab__text-label {
        background-color: $bg-toggle-color;
      }
    }
  }
}

.mat-mdc-tab-nav-bar {
  background-color: $bg-main-color !important;
  border: 1px solid $border-color-10;

  .mdc-tab__ripple,
  .mdc-tab-indicator {
    display: none;
  }

  .mat-mdc-tab-links {
    height: $nav-height;

    .mat-mdc-tab-link {
      flex-grow: unset !important;
      height: auto;
      padding: 0px;
      min-width: auto;
      margin-right: 20px;

      &:last-child {
        margin-right: 0px;
      }

      .mdc-tab__text-label {
        @include c2-r-font-styles();
      }

      &.mdc-tab--active,
      &.mdc-tab--active:focus {
        .mdc-tab__text-label {
          @include c2-m-font-styles();
        }
      }
    }
  }

  &.--as-toggle {
    border: none !important;
    border-radius: 4px;
    padding: 4px 0px;
    height: 24px;

    .mat-mdc-tab-links {
      height: 100%;

      .mat-mdc-tab-link {
        flex-grow: unset !important;
        margin-right: 10px;

        .mdc-tab__text-label {
          padding: 0px 24px;
          height: 24px;
          border-radius: 4px;
        }

        &.mdc-tab--active .mdc-tab__text-label {
          background-color: $bg-toggle-checked-color;
        }

        &:not(.mdc-tab--active) .mdc-tab__text-label {
          background-color: $bg-toggle-color;
        }
      }
    }
  }
}
