$sizes: (
  0: 0,
  2: 2px,
  4: 4px,
  6: 6px,
  8: 8px,
  12: 12px,
  16: 16px,
  20: 20px,
  24: 24px,
  28: 28px,
  32: 32px,
  // TODO: create unique sizes
  5: 5px,
  10: 10px,
  14: 14px,
  15: 15px,
  25: 25px,
  30: 30px,
  35: 35px,
  40: 40px,
  42: 42px,
  48: 48px,
  100: 100px
);

$directions: (
  t: top,
  r: right,
  b: bottom,
  l: left
);

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.w-auto {
  width: auto;

  &--imp {
    width: auto !important;
  }
}

.mw-auto {
  min-width: auto !important;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33.3%;
}

.w-66 {
  width: 66.6%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.h-16 {
  height: 16px;
}

.h-100 {
  height: 100%;

  &--imp {
    height: 100% !important;
  }
}

.h-auto {
  height: auto;

  &--imp {
    height: auto !important;
  }
}

@mixin generate-padding-margin-classes($direction) {
  $css-direction: map-get($directions, $direction);

  @each $key, $value in $sizes {
    .m-#{$direction}-#{$key} {
      margin-#{$css-direction}: $value;

      &--imp {
        margin-#{$css-direction}: $value !important;
      }
    }

    .p-#{$direction}-#{$key} {
      padding-#{$css-direction}: $value;

      &--imp {
        padding-#{$css-direction}: $value !important;
      }
    }
  }
}

@include generate-padding-margin-classes(t);
@include generate-padding-margin-classes(r);
@include generate-padding-margin-classes(b);
@include generate-padding-margin-classes(l);

@each $size, $value in $sizes {
  .w-#{$size}-px {
    width: $value;

    &--imp {
      width: $value !important;
    }
  }

  .min-w-#{$size}-px {
    min-width: $value;

    &--imp {
      min-width: $value !important;
    }
  }

  .h-#{$size}-px {
    height: $value;

    &--imp {
      height: $value !important;
    }
  }

  .m-#{$size} {
    margin: $value;

    &--imp {
      margin: $value !important;
    }
  }

  .p-#{$size} {
    padding: $value;

    &--imp {
      padding: $value !important;
    }
  }

  .g-#{$size} {
    gap: $value;

    &--imp {
      gap: $value !important;
    }
  }
}
