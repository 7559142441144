@import 'core/variables';
@import 'typography/mixins';
@import 'theming/functions';

$bg-main-color: app-color(bg-main-color);
$text-color: app-color(text-main-color);
$border-color-10: app-color(border-color, 0.1);
$border-color-25: app-color(border-color, 0.25);
$bg-color-10: app-color(bg-color, 0.1);
$text-color-50: app-color(text-color, 0.5);
$box-shadow: app-palette-val(box-shadow);

$menu-item-size: 28px;
$menu-item-big-size: 38px;

.mat-mdc-menu-panel {
  --mat-menu-container-color: #{$bg-main-color};

  border-radius: 4px;
  min-height: $menu-item-size;
  margin-top: 3px;

  border: 1px solid $border-color-25;
  box-shadow: $box-shadow !important;

  .mat-mdc-menu-ripple {
    display: none;
  }

  &.--big-menu {
    min-height: $menu-item-big-size;
    padding: 10px;
    width: 190px;
  }

  // set scrollbar styles
  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: $border-color-10;
  }

  .mat-mdc-menu-content {
    padding: 0 !important;
  }

  .mat-mdc-menu-item {
    display: flex;
    align-items: center;
    height: $menu-item-size;
    width: 100%;
    min-height: $menu-item-size;
    padding: 0px 9px;

    .mat-mdc-menu-item-text {
      display: flex;
      align-items: center;
      width: 100%;
      @include c2-r-font-styles(true);
    }

    color: $text-color !important;

    &[disabled='true'] {
      color: $text-color-50;
    }

    &:hover:not([disabled='true']) {
      color: $text-color !important;
      background-color: $bg-color-10;
    }

    .mat-mdc-menu-submenu-icon {
      color: $text-color !important;
    }
  }

  &.--big-menu {
    .mat-mdc-menu-item {
      display: flex;
      align-items: flex-start;
      min-height: $menu-item-big-size;
      height: auto;
      padding: 5px 5px;
      margin-bottom: 5px;
      border-radius: 4px;
      white-space: normal;
    }
  }
}

.mat-mdc-menu-item-submenu-trigger {
  &::after {
    border-width: 4px 0 4px 4px;
    color: $text-color;
  }

  &[disabled='true'] {
    &::after {
      color: $text-color-50;
    }
  }
}

.app-search-menu {
  &-item {
    height: 32px;

    &-icon {
      margin-right: 8px;
    }

    &-title {
      @include c2-r-font-styles();
    }
  }
}
