@import 'typography/mixins';
@import 'theming/functions';

$text-color: app-color(text-main-color);
$text-color-50: app-color(text-color, 0.5);
$border-color-50: app-color(border-color, 0.5);
$bg-block-0-color: app-color(bg-block-0-color);

.mat-expansion-panel {
  margin-bottom: 8px !important;
  border-radius: 4px;
  box-shadow: none !important;
  box-sizing: border-box !important;

  color: $text-color;
  background-color: $bg-block-0-color !important;

  .mat-expansion-panel-header {
    height: 46px;

    @include h5-m-font-styles();

    color: $text-color;
    background-color: $bg-block-0-color !important;

    &[aria-disabled='true'] {
      color: $text-color-50;
    }
  }

  &.mat-expansion-panel-spacing {
    margin: 0px;
  }

  &.--no-bg {
    background-color: transparent !important;

    .mat-expansion-panel-header {
      background-color: transparent !important;
    }
  }

  &.--no-gap {
    margin-bottom: 0px !important;

    .mat-expansion-panel-header {
      padding: 0px;

      .mat-expansion-panel-header-description {
        margin-right: 0px;
        flex-grow: 0;
      }
    }

    .mat-expansion-panel-body {
      padding: 0px !important;
      margin-top: 10px;
    }
  }

  .mat-expansion-panel-content {
    color: $text-color;
  }

  .mat-expansion-panel-header-title {
    align-items: center;

    @include h5-m-font-styles();

    color: $text-color;
  }

  .mat-expansion-panel-header-description {
    justify-content: flex-end;
  }

  .mat-expansion-indicator::after {
    color: $border-color-50;
  }
}
