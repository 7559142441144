@import 'theming/functions';

$bg-color-10: app-color(bg-color, 0.1);
$danger-color: app-color(danger-color);
$danger-color-10: app-color(danger-color, 0.1);
$success-color: app-color(success-color);
$success-color-10: app-color(success-color, 0.1);

.app-badge {
  display: inline-flex;
  align-items: center;
  padding: 1px 4px;
  border-radius: 4px;

  background-color: $bg-color-10;

  &--danger {
    color: $danger-color;
    background-color: $danger-color-10;
  }

  &--success {
    color: $success-color;
    background-color: $success-color-10;
  }
}
