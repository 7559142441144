// Colors
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);

// Typography
$font-size-root: 16px;
$font-weight-bolder: 700;

$small-font-size: 0.875rem; // 14px
$sub-sup-font-size: 0.625rem; // 10px

$paragraph-margin-bottom: 1rem;

$pageContentMaxWidth: 1242px;
