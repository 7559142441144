@import 'theming/functions';

$status-color: app-color(status-color);
$success-color: app-color(success-color);

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #{$status-color};

  &.--success {
    --mdc-circular-progress-active-indicator-color: #{$success-color};
  }
}
