.app-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.app-flex--align-start {
  align-self: flex-start;
}
