@import 'theming/functions';

$text-color: app-color(text-main-color);
$bg-main-color: app-color(bg-main-color);
$bg-main-color-80: app-color(bg-main-color, 0.8);
$border-color-10: app-color(border-color, 0.1);

.app-popup {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: $bg-main-color;
  border: 1px solid $border-color-10;
  color: $text-color;
  z-index: 1000;

  &.--80 {
    background-color: $bg-main-color-80;
  }
}
