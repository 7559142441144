@import 'theming/functions';
@import 'typography/mixins';

$bg-color-05: app-color(bg-color, 0.05);
$border-color-05: app-color(border-color, 0.05);

.app-chip {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: $bg-color-05;
  border: 1px solid $border-color-05;

  @include c2-r-font-styles();
}

.app-order-prefix-snack {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 62px;
  height: 100%;
  min-height: 42px;
  border-radius: 4px;
  margin-right: 10px;
}
