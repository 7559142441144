@import 'theming/functions';

$border-color-05: app-color(bg-color, 0.05);
$border-color-10: app-color(border-color, 0.1);
$border-color-25: app-color(border-color, 0.25);
$border-color-50: app-color(border-color, 0.5);
$border-color-65: app-color(border-color, 0.65);
$danger-color-10: app-color(danger-color, 0.1);
$danger-color-17: app-color(danger-color, 0.17);
$danger-color-65: app-color(danger-color, 0.65);
$warning-color: app-color(warning-color);
$warning-color-17: app-color(warning-color, 0.17);
$warning-color-50: app-color(warning-color, 0.5);
$warning-color-75: app-color(warning-color, 0.75);
$success-color: app-color(success-color);
$success-color-10: app-color(success-color, 0.1);
$success-color-17: app-color(success-color, 0.17);
$status-color: app-color(status-color);
$status-color-17: app-color(status-color, 0.17);

.app-border {
  border: 1px solid $border-color-10;

  &--imp {
    border: 1px solid $border-color-10 !important;
  }

  &-transparent {
    border: 1px solid transparent;
  }

  &-hover {
    &:hover {
      border: 1px solid $border-color-10 !important;
    }
  }

  &-05 {
    border: 1px solid $border-color-05;
  }

  &-10 {
    border: 1px solid $border-color-10;
  }

  &-25 {
    border: 1px solid $border-color-25;
  }

  &-right {
    &-05 {
      border-right: 1px solid $border-color-05;
    }

    &-10 {
      border-right: 1px solid $border-color-10;
    }
  }

  &-top {
    &-05 {
      border-top: 1px solid $border-color-05;
    }

    &-10 {
      border-top: 1px solid $border-color-10;
    }

    &-25 {
      border-top: 1px solid $border-color-25;
    }
  }

  &-bottom {
    &-05 {
      border-bottom: 1px solid $border-color-05;

      &--imp {
        border-bottom: 1px solid $border-color-05 !important;
      }
    }

    &-10 {
      border-bottom: 1px solid $border-color-10;
    }
  }

  &-r2 {
    border-radius: 2px !important;
  }

  &-r4 {
    border-radius: 4px !important;

    &-top {
      border-radius: 4px 4px 0px 0px !important;
    }

    &-bottom {
      border-radius: 0px 0px 4px 4px !important;
    }
  }

  &-r6 {
    border-radius: 6px !important;
  }

  &-r10 {
    border-radius: 10px !important;
  }

  &-r12 {
    border-radius: 10px !important;
  }

  &-r16 {
    border-radius: 16px !important;
  }

  &-r44 {
    border-radius: 44px !important;
  }

  &-r50p {
    border-radius: 50% !important;
  }
}

.app-border-dashed {
  border: 1px dashed $border-color-10;

  &-05 {
    border: 1px dashed $border-color-05;
  }

  &-10 {
    border: 1px dashed $border-color-10;
  }

  &-25 {
    border: 1px dashed $border-color-25;
  }

  &-50 {
    border: 1px dashed $border-color-50;
  }

  &-bottom {
    border-bottom: 1px dashed $border-color-25;
  }
}

.app-border-danger {
  border-color: $danger-color-65;

  &--imp {
    border-color: $danger-color-65 !important;
  }

  &-10 {
    border-color: $danger-color-10;
  }

  &-17 {
    border-color: $danger-color-17;
  }
}

.app-border-warning {
  border-color: $warning-color;

  &-17 {
    border-color: $warning-color-17;
  }

  &-50 {
    border-color: $warning-color-50;
  }

  &-75 {
    border-color: $warning-color-75;
  }
}

.app-border-success {
  border-color: $success-color;

  &-10 {
    border-color: $success-color-10;
  }

  &-17 {
    border-color: $success-color-17;
  }

  &-top {
    border-top: 1px solid $success-color;
  }

  &-bottom {
    border-bottom: 1px solid $success-color;
  }

  &-dashed-bottom {
    border-bottom: 1px dashed $success-color;
  }
}

.app-border-status {
  border-color: $status-color;

  &-17 {
    border-color: $status-color-17;
  }
}
