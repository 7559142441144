@import 'core/screen-sizes';
@import 'typography/mixins';
@import 'theming/functions';

$border-color: app-color(border-color, 0.1);
$sidebar-section-border-color: app-color(border-color, 0.25);
$text-color: app-color(text-main-color);
$sidebar-items-text-color: app-color(text-color, 0.65);
$svg-icon-color: app-color(border-color, 0.25);

@function getPageSidePaddingByScreenSize($size) {
  @return map-get(
    (
      's': 24px,
      'xs': 14px
    ),
    $size
  );
}

.page {
  display: block;
  flex-grow: 1;
  overflow: auto;

  @include less_then_s_screen() {
    padding: 8px getPageSidePaddingByScreenSize('s');
  }

  @include less_then_xs_screen() {
    padding: 2px getPageSidePaddingByScreenSize('xs');
  }

  &--preview {
    @include less_then_s_screen() {
      padding: 0;
    }
  }

  &__content {
    max-width: $pageContentMaxWidth;
    margin-left: auto;
    margin-right: auto;
  }

  &__column {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 20px;
    min-height: 180px;
    width: 50%;

    @include less_then_s_screen() {
      width: 100%;
      padding: 16px 0;
    }

    @include less_then_xs_screen() {
      padding: 12px 0;
    }
  }

  &__added-widget {
    display: block;
    position: relative;
    margin-bottom: 40px;

    &--drag-preview {
      margin-bottom: 0;
    }
  }
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0.3;
}

.marketplace-header {
  display: flex;

  &.product-preview-header {
    padding: 20px 20px 0;

    @include less_then_m_screen() {
      padding: 20px 20px 20px 42px !important;
    }

    @include less_then_s_screen() {
      padding: 20px 13px 0 !important;
    }
  }

  &__content-wrapper {
    width: 100%;
    overflow: hidden;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    flex-shrink: 0;
    padding: 6px 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: $border-color;

    &--tab-nav {
      border-bottom: 0;
      padding-bottom: 0;
    }

    .mat-mdc-tab-nav-bar::after {
      border-color: $border-color;
    }
  }

  &__nav {
    &--tab-nav {
      width: 100%;
    }
  }
}

.marketplace-sidebar-section {
  & + & {
    margin-top: 4px;
  }

  &__header {
    margin: 0;
    @include h4-b-font-styles();
    color: $text-color;
  }

  &__content {
    margin-top: 8px;
    border-top-width: 1px;
    border-top-style: solid;
    padding: 6px 0;
    border-color: $sidebar-section-border-color;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  &__column {
    flex-basis: 50%;
    width: 50%;
    flex-shrink: 1;
    padding: 6px 10px;

    &--full-width {
      flex-basis: 100%;
      width: 100%;
    }
  }
}

.marketplace-sidebar-items {
  color: $sidebar-items-text-color;

  &__title {
    @include b4-r-font-styles();
  }

  &__list,
  &__item-wrapper {
    padding: 6px 0;
  }

  &__item-wrapper {
    cursor: grab;

    &--disabled {
      cursor: not-allowed;
      opacity: 0.3;

      .app-svg {
        svg {
          cursor: not-allowed;
        }
      }
    }
  }

  &__item {
    @include b4-r-font-styles();

    &--image {
      min-height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;

      .mat-icon {
        width: 32px;
        height: 32px;
      }
    }

    &--special {
      height: 104px;
    }
  }

  &__item-label {
    @include b4-r-font-styles();
  }
}

.marketplace-icon {
  height: 100%;

  svg {
    [fill] {
      fill: $svg-icon-color;
      fill-opacity: 1;
    }

    [stroke] {
      stroke: $svg-icon-color;
      stroke-opacity: 1;
    }
  }
}

.cdk-drag-preview {
  &.widget-preview {
    border-width: 0;
  }
}
