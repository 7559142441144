@use 'ag-grid-community/styles' as ag;

// core
@import 'core/variables';
@import 'core/animations';
@import 'core/base';
@import 'core/page-container';

// typography
@import 'typography/typography';
@import 'typography/pdf-typography';

// common styles
@import 'common';

// material
@import 'material';

// ag-grid
@include ag.grid-styles();

// ng-select
@import '@ng-select/ng-select/themes/material.theme.css';

// ngx-editor
@import 'ngx-editor/ngx-editor';

// highcharts
@import 'highcharts/highcharts';

// theming
@import 'theming/themes';

// marketplace
@import 'src/app/marketplace/scss';
