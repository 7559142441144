@import 'core/variables';
@import 'typography/mixins';
@import 'theming/functions';

$text-color: app-color(text-main-color);
$text-color-25: app-color(text-color, 0.25);
$text-color-25: app-color(text-color, 0.25);
$text-color-65: app-color(text-color, 0.65);
$text-color-75: app-color(text-color, 0.75);
$border-color: app-color(border-color);
$border-color-05: app-color(border-color, 0.05);
$border-color-10: app-color(border-color, 0.1);
$border-color-25: app-color(border-color, 0.25);
$border-color-65: app-color(border-color, 0.65);
$bg-color-25: app-color(bg-color, 0.25);
$bg-color-65: app-color(bg-color, 0.65);
$text-inverse-color: app-color(text-inverse-color);

.mat-mdc-checkbox {
  display: inline-flex !important;
  height: $input-small-height;

  .mat-mdc-checkbox-touch-target,
  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    display: none;
  }

  .mdc-checkbox {
    --mdc-checkbox-state-layer-size: 18px;

    padding: 0px 4px 0px 0px;
  }

  .mdc-checkbox__native-control {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }

  &.--small {
    height: 24px;

    .mdc-checkbox {
      --mdc-checkbox-state-layer-size: 16px;
    }

    .mdc-checkbox__background {
      width: 16px;
      height: 16px;
    }
  }

  .mdc-label {
    @include c2-r-font-styles();

    text-transform: none !important;
    white-space: nowrap;
    color: $text-color-75;

    &:first-letter {
      text-transform: capitalize !important;
    }
  }

  .mdc-checkbox__background {
    border-width: 1px;
    border-radius: $input-border-radius;
    transition:
      border-color 0.26s,
      background-color 0.26s;
    top: 0 !important;
    left: 0 !important;
  }

  .mdc-checkbox__checkmark {
    height: 90%;
  }

  &.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      border-color: $border-color-05 !important;
      background-color: $bg-color-65 !important;

      .mdc-checkbox__checkmark-path {
        stroke: $text-inverse-color !important;
      }
    }

    &.mat-mdc-checkbox-disabled {
      .mdc-label {
        color: $text-color-25 !important;
      }

      .mdc-checkbox__background {
        border-color: $border-color-05;
        background-color: $bg-color-25 !important;
      }
    }

    &:not(.mat-mdc-checkbox-disabled) {
      .mdc-label {
        color: $text-color-75;
      }

      .mdc-checkbox__background {
        border-color: $border-color-05;
        background-color: $bg-color-25;
      }

      &:hover {
        .mdc-label {
          color: $text-color !important;
        }

        .mdc-checkbox__background {
          border-color: $border-color;
        }
      }
    }
  }

  &:not(.mat-mdc-checkbox-checked) {
    .mdc-checkbox__background {
      border-color: $border-color-65 !important;
      background-color: transparent;

      .mdc-checkbox__checkmark-path {
        stroke: transparent !important;
      }
    }

    &.mat-mdc-checkbox-disabled {
      .mdc-label {
        color: $text-color-25 !important;
      }

      .mdc-checkbox__background {
        border-color: $border-color-25 !important;
      }
    }

    &:not(.mat-mdc-checkbox-disabled) {
      &:hover {
        .mdc-label {
          color: $text-color !important;
        }

        .mdc-checkbox__background {
          border-color: $border-color !important;
        }
      }
    }
  }
}
