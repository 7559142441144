@use 'sass:map';

@import 'font-variables';

@mixin _set-font-styles(
  $font-style,
  $font-weight,
  $font-size,
  $line-height,
  $letter-spacing,
  $text-transform
) {
  font-family: $font-family;
  font-style: $font-style;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;

  letter-spacing: $letter-spacing;
  text-transform: $text-transform;
}

@mixin _set-font-styles-important(
  $font-style,
  $font-weight,
  $font-size,
  $line-height,
  $letter-spacing,
  $text-transform
) {
  font-family: $font-family !important;
  font-style: $font-style !important;
  font-weight: $font-weight !important;
  font-size: $font-size !important;
  line-height: $line-height !important;

  letter-spacing: $letter-spacing !important;
  text-transform: $text-transform !important;
}

@mixin inherit-font-styles() {
  font-family: $font-family;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;

  letter-spacing: inherit;
  text-transform: inherit;
}

// helpers

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin first-letter-up() {
  &::first-letter {
    text-transform: uppercase !important;
  }
}

@mixin lines-limit($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin h1-sp-font-styles() {
  @include _set-font-styles(normal, 500, 50px, 52px, -1px, capitalize);
}

@mixin h2-sp-font-styles() {
  @include _set-font-styles(normal, 500, 32px, 35px, normal, capitalize);
}

@mixin h3-sp-font-styles() {
  @include _set-font-styles(normal, 500, 28px, 30px, normal, capitalize);
}

@mixin h4-sp-font-styles() {
  @include _set-font-styles(normal, 500, 22px, 25px, normal, capitalize);
}

@mixin h1-b-font-styles() {
  @include _set-font-styles(normal, bold, 28px, 33px, 0.4px, capitalize);
}

@mixin h2-b-font-styles() {
  @include _set-font-styles(normal, bold, 20px, 33px, 0.8px, capitalize);
}

@mixin h2-m-font-styles() {
  @include _set-font-styles(normal, 500, 20px, 33px, 0.8px, capitalize);
}

@mixin h3-b-font-styles() {
  @include _set-font-styles(normal, bold, 16px, 20px, 0.2px, capitalize);
}

@mixin h4-b-font-styles() {
  @include _set-font-styles(normal, bold, 14px, 16px, 0.2px, capitalize);
}

@mixin h4-m-font-styles() {
  @include _set-font-styles(normal, 500, 14px, 16px, 0.2px, capitalize);
}

@mixin h5-b-font-styles() {
  @include _set-font-styles(normal, bold, 12px, 16px, 0.6px, capitalize);
}

@mixin h5-m-font-styles() {
  @include _set-font-styles(normal, 500, 12px, 16px, 0.6px, capitalize);
}

@mixin b1-r-font-styles() {
  @include _set-font-styles(normal, normal, 22px, 25px, normal, capitalize);
}

@mixin b2-r-font-styles() {
  @include _set-font-styles(normal, normal, 16px, 20px, normal, capitalize);
}

@mixin b3-r-font-styles() {
  @include _set-font-styles(normal, normal, 14px, 16px, normal, capitalize);
}

@mixin b4-r-font-styles() {
  @include _set-font-styles(normal, normal, 12px, 16px, normal, capitalize);
}

@mixin b5-r-font-styles() {
  @include _set-font-styles(normal, normal, 10px, 12px, normal, capitalize);
}

@mixin c1-r-font-styles() {
  @include _set-font-styles(normal, normal, 14px, 16px, normal, capitalize);
}

@mixin c1-m-font-styles() {
  @include _set-font-styles(normal, 500, 14px, 16px, normal, capitalize);
}

@mixin c2-r-font-styles($important: false) {
  @if $important {
    @include _set-font-styles-important(normal, normal, 12px, 16px, normal, capitalize);
  } @else {
    @include _set-font-styles(normal, normal, 12px, 16px, normal, capitalize);
  }
}

@mixin c2-m-font-styles() {
  @include _set-font-styles(normal, 500, 12px, 12px, normal, capitalize);
}

@mixin c3-r-font-styles() {
  @include _set-font-styles(normal, normal, 10px, 12px, normal, capitalize);
}
