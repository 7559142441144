@import 'core/variables';
@import 'typography/mixins';
@import 'theming/functions';

$text-main-color: app-color(text-main-color);
$text-color-25: app-color(text-color, 0.25);
$text-color-50: app-color(text-color, 0.5);
$text-color-65: app-color(text-color, 0.65);
$text-inverse-color: app-color(text-inverse-color);
$border-color: app-color(border-color);
$border-color-10: app-color(border-color, 0.1);
$border-color-25: app-color(border-color, 0.25);
$field-focused-color: app-color(field-focused-color);
$bg-color: app-color(bg-color);
$bg-main-color: app-color(bg-main-color);
$box-shadow: app-palette-val(box-shadow);

// appearance="outline"
.mat-mdc-form-field {
  width: 100%;

  &.mat-form-field-appearance-outline {
    --mdc-outlined-text-field-outline-color: #{$border-color-10};
    --mdc-outlined-text-field-disabled-outline-color: #{$border-color-10};
    --mdc-outlined-text-field-hover-outline-color: #{$border-color-25};
    --mdc-outlined-text-field-focus-outline-color: #{$field-focused-color};
    --mdc-outlined-text-field-input-text-color: #{$text-main-color};
    --mdc-outlined-text-field-caret-color: #{$text-main-color};
    --mdc-outlined-text-field-disabled-input-text-color: #{$text-color-50};

    height: $input-small-height;

    .mat-mdc-form-field-infix {
      min-height: unset;
      padding: 0px !important;
    }

    .mat-mdc-text-field-wrapper {
      padding: 4px 10px 4px 10px !important;
    }

    .mat-mdc-input-element {
      @include c2-r-font-styles();

      text-transform: none !important;

      &::placeholder {
        color: $text-color-50 !important;
      }
    }

    &.mat-form-field-disabled .mat-mdc-input-element {
      color: $text-color-50;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.mat-datepicker-content {
  border: none;
  box-shadow: none !important;
  background-color: transparent;
  color: $text-main-color !important;

  .mat-calendar-next-button,
  .mat-calendar-previous-button {
    color: $text-color-65 !important;
  }

  .mat-calendar {
    height: auto !important;
  }

  .mat-mdc-button {
    padding: 0;
    border: none;
  }
}

.mat-calendar {
  .mat-mdc-button-persistent-ripple {
    display: none;
  }

  .mat-calendar-table-header {
    color: $text-color-65 !important;

    th {
      color: $text-color-65 !important;
      @include b5-r-font-styles();
    }
  }

  .mat-calendar-controls {
    margin-top: 6px !important;
    color: $text-main-color !important;
  }

  .mat-calendar-body-label {
    color: $text-color-65 !important;
    @include b3-r-font-styles();
  }

  .mat-calendar-period-button {
    flex-grow: 1;
    padding: 0 !important;
    color: $text-main-color !important;
    @include b3-r-font-styles();
  }

  .mat-calendar-body-cell-content {
    @include h4-m-font-styles();

    width: 80% !important;
    height: 80% !important;
    border-radius: 4px !important;

    color: $text-main-color;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled) {
    &:hover {
      > .mat-calendar-body-cell-content:not(.mat-calendar-body-today) {
        color: $text-inverse-color !important;
        background-color: $bg-color !important;
      }
    }
  }

  .mat-calendar-table-header-divider::after {
    content: none;
    background-color: transparent;
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: $border-color !important;
  }

  .mat-calendar-body-selected {
    color: $text-inverse-color;
    background-color: $text-main-color;
  }

  .mat-calendar-body-disabled {
    .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
      ) {
      color: $text-color-25 !important;
    }
  }

  .mat-calendar-body-in-preview {
    color: $text-color-50;
  }

  .mat-calendar-previous-button {
    order: -1;
  }

  .mat-calendar-spacer {
    display: none;
  }

  .mat-calendar-arrow {
    fill: $text-color-65;
  }
}

.mat-datepicker-actions {
  padding: 0px !important;
}

.mat-datepicker-popup {
  border: 1px solid;
  border-radius: 4px;

  color: $text-main-color;
  border-color: $border-color-25;
  background-color: $bg-main-color;
  box-shadow: $box-shadow;
}
