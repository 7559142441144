@import 'theming/functions';

$bg-color-05: app-color(bg-color, 0.05);
$success-text-color: app-color(success-color);
$warning-text-color: app-color(warning-color);
$danger-text-color: app-color(danger-color);
$success-bg-color: app-color(success-color, 0.1);
$warning-bg-color: rgba(app-color(warning-color), 0.1);
$danger-bg-color: rgba(app-color(danger-color), 0.1);
$bg-block-0-color: app-color(bg-block-0-color);
$bg-block-1-color: app-color(bg-block-1-color);

.app-card {
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  background-color: $bg-color-05;

  &-title {
    padding: 2px 16px;
    border-radius: 12px;
    display: inline-flex;

    &.success {
      color: $success-text-color;
      background-color: $success-bg-color;
    }

    &.warning {
      color: $warning-text-color;
      background-color: $warning-bg-color;
    }

    &.danger {
      color: $danger-text-color;
      background-color: $danger-bg-color;
    }
  }
}

@mixin app-fields-block() {
  border-radius: 4px;

  .app-input,
  .app-input-stub,
  .app-select {
    margin-bottom: 0;
  }

  .app-input,
  .app-select,
  .app-field-selector {
    background-color: $bg-block-1-color !important;
  }

  .app-input-stub {
    &.--fill {
      background-color: $bg-block-1-color !important;
    }
  }

  .app-input {
    &--textarea {
      .mat-mdc-form-field-flex {
        textarea {
          &::-webkit-resizer {
            background-color: $bg-block-1-color;
          }
        }
      }
    }
  }
}

.app-fields-block {
  @include app-fields-block();

  background-color: $bg-block-0-color;
}

.app-fields-block-no-bg {
  @include app-fields-block();
}

.page-spinner-block {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.dialog-spinner-block {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 74vh;
}
