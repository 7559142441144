@import 'theming/functions';

$text-color: app-color(text-main-color);

.mat-tree {
  background-color: transparent;
  color: $text-color;
}

.mat-tree-node {
  color: $text-color;
}

.mat-nested-tree-node {
  color: $text-color;
}
