@import 'core/variables';
@import 'typography/mixins';
@import 'theming/functions';

$text-color: app-color(text-main-color);
$text-color-50: app-color(text-color, 0.5);
$text-color-65: app-color(text-color, 0.65);
$bg-main-color: app-color(bg-main-color);
$bg-color: app-color(bg-color);
$bg-color-05: app-color(bg-color, 0.05);
$bg-color-10: app-color(bg-color, 0.1);
$bg-color-25: app-color(bg-color, 0.25);
$border-color-10: app-color(border-color, 0.1);
$border-color-25: app-color(border-color, 0.25);
$border-color-50: app-color(border-color, 0.5);

.mat-mdc-select {
  --mat-select-enabled-arrow-color: #{$border-color-50};
  --mat-select-focused-arrow-color: #{$border-color-50};

  .mat-mdc-select-value {
    color: $text-color;

    @include c2-r-font-styles();
    white-space: nowrap;
  }

  .mat-mdc-select-placeholder {
    color: $text-color-50;
    @include c1-r-font-styles();
  }
}

.mat-mdc-select-panel {
  border: 1px solid;
  border-radius: $input-border-radius;
  background-color: $bg-main-color !important;
  border-color: $border-color-25;

  // set scrollbar styles
  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: $border-color-10;
  }

  .mat-mdc-option {
    @include c2-r-font-styles();
    text-transform: none !important;
    white-space: nowrap;

    height: 28px !important;
    min-height: 28px;
    padding: 0px 10px;
    margin: 2px 5px;
    border-radius: 4px;

    color: $text-color !important;

    &:hover {
      background-color: $bg-color-05;
    }

    &.mat-active {
      background-color: $bg-color-05;
    }

    &.mdc-list-item--disabled {
      color: $text-color-50;
    }

    &.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
      color: $text-color-50;
      background-color: $bg-color-05;

      .mdc-list-item__primary-text {
        color: $text-color-50;
      }
    }
  }
}
