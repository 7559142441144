@import 'core/variables';
@import 'theming/functions';

$border-color-10: app-color(border-color, 0.1);

$bg-main-color: app-color(bg-main-color);
$text-color: app-color(text-main-color);

.mat-drawer {
  --mat-sidenav-container-text-color: #{$text-color};
  --mat-sidenav-content-text-color: #{$text-color};
  --mat-sidenav-container-background-color: #{$bg-main-color};

  .mat-drawer-inner-container {
    &::-webkit-scrollbar {
      width: $scrollbar-size;
      height: $scrollbar-size;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $border-color-10;
    }
  }
}

.mat-drawer-container {
  --mat-sidenav-container-text-color: #{$text-color};
  --mat-sidenav-content-text-color: #{$text-color};
  --mat-sidenav-container-background-color: #{$bg-main-color};
  background-color: transparent !important;
}
