.app-expander {
  display: flex;
  margin-right: 5px;
  transition: transform 0.3s ease-in-out;

  &--up {
    transform: rotate(180deg);
  }
}

.app-expander-icon {
  user-select: none;

  &--down {
    svg {
      transform: rotate(90deg);
    }
  }
}
